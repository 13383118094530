// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v0.0.1',
  sessionKey: 'AuthThYUHsHd',
  menuKey: 'da-menu',
  identityServiceUrl: 'https://login-api.dev.dentistryautomation.com/',
  // dentaFlowUrl: 'https://localhost:7206',
  dentaFlowUrl: 'https://coreapi.dev.dentistryautomation.com',
  logOutUrl: 'https://core.dev.dentistryautomation.com/auth/sign-in',
  productApiUrl: 'https://claimstracker-api.dev.dentistryautomation.com',
  instrumentationKey:'d6eff7a8-07af-4118-9ea3-785b0502aaa3',
  baseurl: 'https://claimstracker.dev.dentistryautomation.com',
  // baseurl: 'https://localhost:4201',
  eligibilityUrl:'https://eligibility-api.dev.dentistryautomation.com',
  // productApiUrl:'http://localhost:7203',
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
