import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ToastService } from 'src/app/shared/toast/services/toast-service';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor
{
    constructor(private toastService: ToastService, private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any>
    {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) =>
                {
                    if (error.error instanceof ErrorEvent)
                    {
                        if (!environment.production)
                        {
                            
                        }
                    }
                    else
                    {
                        if (!environment.production)
                        {
                            
                        }
                        if (error.status == 0)
                        {
                            if (!(localStorage.getItem(LOCAL_STORAGE.TOKEN))) {
                            // this.toastService.error('This session is not valid any more');
                                setTimeout(() => {
                                    // this.authenticationService.logout();
                                }, 1000);
                            }
                            // this.toastService.showConnectionError();
                        }
                        else if (error.status == 401)
                        {
                            setTimeout(() => {
                                this.authenticationService.logout();
                            }, 500);
                        }
                    }
                    return throwError(error);
                })
            );
    }
}
